// PdfDocument.js
import React from 'react';
import { Page, Text,Font ,View, Document, StyleSheet, Image } from '@react-pdf/renderer';

Font.register({
    family: 'Arial',
    fonts:[
        {src:'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf'},
        {src:'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf', fontWeight: 700}
    ]
  });
// Definimos los estilos

const tableCol = {
     
    borderStyle: "solid" as const, 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    width:'20%',
}

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Arial',
    margin:40,
    fontSize:12,
    position:'relative'

  },
  header: {    
    marginTop: 20,
    fontWeight:700,
    width:'87%',
  },
  placaCol:{
    ...tableCol,
  },
  marcaCol:{
    ...tableCol,
  },
  fechaCol:{
    ...tableCol,
  },
  modeloCol:{
    ...tableCol,
  },
  imageNavitrac:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  section:{
    width:'87%'
  },
  titulo:{
    fontSize: 20,
    fontWeight:700
  },
  bold:{
    fontWeight:700
  },
  table: { 
    marginTop:20,
    width:"86%",
    display: "flex", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "20%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
  }, 
  tableCellBold: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10,
    fontWeight:700,
    
  },
  tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10,
  },


  signature: {
    width:60,
    height:60,
    position:"relative",
  },
  cornerIcon: {    
    position: 'absolute',
    top: -40,
    left:-40,
    width: '80px',
    height: '50px%',
  },
  footerImage: {
    position:"absolute",
    left:-20,
    bottom:60,
    //left:-40,
    //bottom:-20,
    width: '93%',
  }
});



const PdfDocument = (props:any) => (
  <Document>
    <Page size="A4" style={styles.body}>
        <View style={styles.section} >
            <Image
                style={styles.cornerIcon}
                src='https://assests-navitrac.s3.us-east-2.amazonaws.com/CERTIFICADO+DE+SERVICIO/ICONO+ESQUINERO+PARA+CERTFICADO.jpeg'                
            />
            
            <Text >{'\n'}{'\n'}</Text>
            <View style={styles.imageNavitrac}>
                <Image
                src="https://assests-navitrac.s3.us-east-2.amazonaws.com/CERTIFICADO+DE+SERVICIO/LOGO-GRANDE.png"
                style={{ width: '30%' }}
                />
            </View>
            <Text >{'\n'}{'\n'}</Text>
            <Text style={{fontWeight:700, fontSize:16, textAlign: 'center', marginTop: 20 }}>
                Certificado de Servicio GPS
            </Text>
            <Text style={{ marginTop: 30, marginBottom:10 }}>
                Mediante la presente certifico que el cliente: <Text style={styles.bold}>{props.cliente}</Text> con cédula <Text style={styles.bold}>{props.ciruc}</Text> tiene vigente el servicio de rastreo GPS Navitrac para el siguiente vehículo:
            </Text>
        </View>
      

      <View style={styles.table}> 
        <View style={styles.tableRow}> 
          <View style={styles.marcaCol}> 
            <Text style={styles.tableCellBold}>MARCA</Text> 
          </View> 
          <View style={styles.modeloCol}> 
            <Text style={styles.tableCellBold}>MODELO</Text> 
          </View> 
          <View style={styles.placaCol}> 
            <Text style={styles.tableCellBold}>PLACA/CHASIS</Text> 
          </View> 
          <View style={styles.fechaCol}> 
            <Text style={styles.tableCellBold}>FECHA INSTALACIÓN</Text> 
          </View> 
          <View style={styles.fechaCol}> 
            <Text style={styles.tableCellBold}>FECHA VENCIMIENTO</Text> 
          </View> 
        </View>
        <View style={styles.tableRow}> 
          <View style={styles.marcaCol}> 
            <Text style={styles.tableCell}>{props.marca}</Text> 
          </View> 
          <View style={styles.modeloCol}> 
            <Text style={styles.tableCell}>{props.modelo} </Text> 
          </View> 
          <View style={styles.placaCol}>
            <Text style={styles.tableCell}>{props.placa}</Text> 
          </View>
          <View style={styles.fechaCol}> 
            <Text style={styles.tableCell}>{props.finst}</Text> 
          </View> 
          <View style={styles.fechaCol}> 
            <Text style={styles.tableCell}>{props.fvenc}</Text> 
          </View> 
        </View> 
      </View>
 
      
      <Text style={styles.header}>
        Nuestro software Navitrac permite gestionar y ubicar mediante geolocalización en tiempo real el vehículo en mención.
      </Text>
      <Text style={{ marginTop: 20 }}>
        Se expide el presente certificado a solicitud del cliente para los fines que crea conveniente.
      </Text>
      <Text >{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}</Text>
      <View style={{marginVertical:'15'}} >
        <Image
          src="https://assests-navitrac.s3.us-east-2.amazonaws.com/CERTIFICADO+DE+SERVICIO/QR+ROSMERY+PARA+CERTIFICADO.png"
          style={styles.signature}
        />
        
      </View>
      <View>
      <Text>Cordialmente,</Text>
        <Text>ROSMERY MUNOZ ALDAZ</Text>
        <Text>Líder de servicio al cliente</Text>
        <Text>TECNOLOGIA INTELIGENTE EN MOVILIDAD TECNIMOV S.A</Text>
        <Text>RUC: 0190490548001</Text>
        <Text>Dirección: Del Arupo y De Las Acacias Cuenca - Ecuador</Text>
        <Text>Cel: 0961771290</Text>
        <Text>ventas@navitrac.com.ec</Text>
      </View>
      <Image
        style={styles.footerImage}
        src="https://assests-navitrac.s3.us-east-2.amazonaws.com/CERTIFICADO+DE+SERVICIO/PIE+DE+PAGINA+CERTIFICADO.png"
      />
    </Page>
  </Document>
);

export default PdfDocument;
