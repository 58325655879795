import { useState, useEffect } from "react";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../state/auth/contex";
import { ICliente } from "../../../state/clientes/types";
import { useClienteContext } from "../../../state/clientes/context";
import { useCookies } from "react-cookie";
import { API } from "aws-amplify";

const apiName = `soporteNavitrac-${process.env.REACT_APP_ENV}`;

const Main = () => {
  const navigate = useNavigate();
  const { user, onLogout } = useAuth();
  const { onSetCliente } = useClienteContext();
  const [clienteSeleccionado, setClienteSeleccionado] = useState<
    ICliente | undefined
  >();
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["usuario", "cliente"]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const getCliente = async () => {
    try {
      setLoading("clientes");
      const result = await API.get(apiName, `/gpswox_clientes/${filter}`, {});
      console.log("Usuario obtenido: ", result);
      setLoading("");
      setClienteSeleccionado(result.cliente);
      if (!clienteSeleccionado) {
        setErrorMessage("No se encontró el cliente especificado");
      }
    } catch (error) {
      setLoading("");
    }
  };
  useEffect(() => {
    const verificarCliente = async () => {
      const cliente = await cookies["cliente"];
      if (cliente) {
        onSetCliente(cliente);
        navigate("/dashboard");
      }
    };
    verificarCliente();
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#c9c9c9",
          height: 50,
        }}
      >
        <h2>
          Soporte Navitrac{" "}
          {process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV} -{" "}
          {user?.username}
        </h2>
        <button
          onClick={() => {
            removeCookie("usuario");
            onLogout();
          }}
        >
          Salir
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <h3>BÚSQUEDA POR USUARIO</h3>
        <div
          style={{
            padding: 20,
            backgroundColor: "lightgray",
            height: 120,
            width: 340,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label>Contrato, Placa, Email, Nombres, Cédula o RUC:</label>
          <input
            value={filter}
            onChange={(e) => {
              setErrorMessage(undefined);
              setFilter(e.target.value);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setErrorMessage(undefined);
                getCliente();
              }
            }}
            placeholder={"Buscar..."}
          />
          {loading !== "" ? (
            <ClipLoader />
          ) : (
            <button
              onClick={() => {
                setErrorMessage(undefined);
                getCliente();
              }}
              style={{ width: 120 }}
            >
              Buscar
            </button>
          )}
          {errorMessage && <label>{errorMessage}</label>}
        </div>
      </div>
      <Modal isOpen={clienteSeleccionado ? true : false}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h2>Usuario Encontrado:</h2>
              <h1 className="titleM">{clienteSeleccionado?.email}</h1>
              <h3 className="titleM-r">{clienteSeleccionado?.email}</h3>
              <h2>Validar información antes de continuar</h2>
            </div>
            <br />
            <h2>
              Apellidos: <span>{clienteSeleccionado?.apellidos}</span>
            </h2>
            <h2>
              Nombres: <span>{clienteSeleccionado?.nombres}</span>
            </h2>
            <h2>
              Razón Social: <span>{clienteSeleccionado?.razon_social}</span>
            </h2>
            <h2>
              Cédula/RUC: <span>{clienteSeleccionado?.ciruc}</span>
            </h2>
            <h2>
              Ciudad: <span>{clienteSeleccionado?.ciudad}</span>
            </h2>
            <h2>
              Teléfono: <span>{clienteSeleccionado?.telefono_1}</span>
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "25vh",
            }}
          >
            <button
              onClick={() => {
                setClienteSeleccionado(undefined);
                setErrorMessage("");
              }}
            >
              Cancelar
            </button>
            <button
              onClick={async () => {
                clienteSeleccionado && onSetCliente(clienteSeleccionado);
                try {
                  await API.put(apiName, '/usuarios', {
                    body: {
                      operation: 'access_account',
                      data: {
                        sesionId: user?.sessionId,
                        userId: clienteSeleccionado?.id
                      }
                    }
                  })
                } catch (e) {
                  console.error(e);
                }
                setCookie("cliente", clienteSeleccionado, { maxAge: 86400 });
                navigate("/dashboard");
              }}
            >
              Continuar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
