import React from 'react';

export const AuthContext = React.createContext<IContextType>({ user: undefined, onLogin: (user: IUser) => { }, onLogout: () => { } });
export const useAuth = () => React.useContext(AuthContext);

interface IUser {
    username: string;
    sessionId?: string;
}

interface IContextType {
    user?: IUser;
    onLogin: (user: IUser) => void;
    onLogout: () => void;
}