import { useEffect, useRef, useState } from "react";
import { IPosition, IVehiculo } from "../../../state/vehiculos/types";
import { ClipLoader } from "react-spinners";
import Modal from "react-modal";
import { useAuth } from "../../../state/auth/contex";
import { FaLocationArrow } from "react-icons/fa";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Polyline,
} from "react-leaflet";
import { DivIcon } from "leaflet";
import "./styles.css";
import { useClienteContext } from "../../../state/clientes/context";
import * as ReactDomServer from "react-dom/server";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import 'dayjs/locale/es';
import dayjs from "dayjs";
import "dayjs/locale/es"; // Importa el idioma español
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { IRenovacionCancelacion } from "../../../state/renovacionCancelacion/types";
import ConsolaSMS from "../../../components/consolaSMS";
import BotonComando from "../../../components/CommandButton";
import DateTimePicker from "react-datetime-picker";
import { Storage } from "aws-amplify";
import Drawer from "react-modern-drawer";
import { API } from "aws-amplify";
import "react-modern-drawer/dist/index.css";
import { IProductosRenovacion } from "../../../state/productosRenovacion/type";
import axios from "axios";
import PdfDocument from './PdfDocuments';
import { PDFViewer, pdf } from '@react-pdf/renderer';
const apiName = `soporteNavitrac-${process.env.REACT_APP_ENV}`;

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.extend(localizedFormat);
dayjs.locale("es");

dayjs.tz.setDefault("Amercia/Bogota");
const divIcon = (course: number) => {
  // console.log(`rotate(${course}deg)`);
  return new DivIcon({
    className: "customIcon",
    html: ReactDomServer.renderToString(
      <FaLocationArrow
        size={30}
        color={"red"}
        style={{ transform: `rotate(${course - 45}deg)` }}
      />
    ),
  });
};

const Main = () => {
  const viewerRef = useRef<PDFViewer | null>(null);
  const navigate = useNavigate();
  const { user, onLogout } = useAuth();
  const { clienteSeleccionado, onSetCliente } = useClienteContext();
  const [loading, setLoading] = useState("");
  const [vehiculos, setVehiculos] = useState<IVehiculo[]>([]);
  const [filterVehiculos, setFilterVehiculos] = useState("");
  const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState<
    | { name: string; id: number; device_data: { sim_number: string } }
    | undefined
  >();
  const [vehiculo, setVehiculo] = useState<IVehiculo | undefined>();
  const [editarEmail, setEditarEmail] = useState(false);
  const [editarVehiculo, setEditarVehiculo] = useState(false);
  const [cookie, setCookie, removeCookie] = useCookies(["cliente"]);
  const [emailEdit, setEmailEdit] = useState("");
  const [nombreEdit, setNombreEdit] = useState("");
  const [placaEdit, setPlacaEdit] = useState("");
  const [chasisEdit, setChasisEdit] = useState("");
  const [openModal, setOpenModal] = useState<
    | undefined
    | "renovaciones"
    | "ticket"
    | "finalizar"
    | "ubicacion"
    | "confirmar_revision"
    | "certificado"
  >(undefined);
  const [bodyMail, setBodyMail] = useState("");
  const [fieldProblema, setFieldProblema] = useState("");
  const [fieldSolucion, setFieldSolucion] = useState("");
  const [fieldCorreoCertificado, setFieldCorreoCertificado] = useState("");
  const [telefonoEdit, setTelefonoEdit] = useState("");
  const [editarNumero, setEditarNumero] = useState(false);
  const [productosRenovacion, setProductosRenovacion] = useState<
    IProductosRenovacion[]
  >([]);
  const [nombreVendedor, setNombreVendedor] = useState("");
  const [ciVendedor, setCiVendedor] = useState("");
  const [valorRenovacion, setValorRenovacion] = useState("");
  const [prodRenovSeleccionada, setProdRenovSeleccionada] = useState("");
  const [estadoEnvioCertificado, setEstadoEnvioCertificado] = useState<
    undefined | "enviando" | "enviado" | "error"
  >(undefined);
  const [factAutoContifico, setFactAutoContifico] = useState<boolean>(false);
  // const [operacionRC, setOperacionRC] = useState({ tipo: '', fecha: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"), factura: '', comprobante: {}, notas: '' })
  const [operacionRC, setOperacionRC] = useState<IRenovacionCancelacion>({
    tipo: "",
    fecha: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
    factura: "",
    comprobante: null,
    notas: "",
  });
  const [guardando, setGuardando] = useState(false);
  // const [disabledBtn, setDisabledBtn] = useState(false);

  const makePause = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Done");
      }, 5000);
    });

  const getComprobanteRC = (archivos: FileList) => {
    console.log(archivos);
    setOperacionRC((prevState) => ({ ...prevState, comprobante: archivos[0] }));
  };

  const generarCertificado = async () => {
    //const url ="https://prod-08.brazilsouth.logic.azure.com:443/workflows/94ed91eadb234380a70fbd682e4a3c74/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Oijn897HXnPTaj5fdek8y9T-L51NKMkDdXioGO_H-fg";
    const url= "https://hooks.zapier.com/hooks/catch/4191687/228k6v7/";
      const blob = await pdf(<PdfDocument ciruc={clienteSeleccionado?.ciruc} cliente={`${clienteSeleccionado?.nombres} ${clienteSeleccionado?.apellidos}`} marca={vehiculo?.marca ?? ""} modelo= {vehiculo?.modelo ?? ""} placa={vehiculo?.placa ?? vehiculo?.chasis} finst={dayjs(vehiculo?.device_data.created_at).format("DD-MM-YYYY")} fvenc={dayjs(vehiculo?.device_data.expiration_date).format("DD-MM-YYYY")}/>).toBlob();
      const formData = new FormData();
      formData.append("nombre",`CERTIFICADO NAVITRAC ${clienteSeleccionado?.nombres}-${vehiculo?.modelo ?? ""} ` )
      formData.append("cliente",`${clienteSeleccionado?.nombres} ${clienteSeleccionado?.apellidos}`)
      formData.append("modelo", `${vehiculo?.marca ?? ""} ${vehiculo?.modelo ?? ""}` )
      formData.append('mypdf', blob, `certificado_${vehiculo?.placa ?? vehiculo?.chasis}.pdf`);
      formData.append("email", fieldCorreoCertificado)
      console.log(blob)
    /*const data = {
      fecha: dayjs().format("dddd, D [de] MMMM [de] YYYY"),
      nombre: `${clienteSeleccionado?.nombres} ${clienteSeleccionado?.apellidos}`,
      ciruc: clienteSeleccionado?.ciruc,
      marca: vehiculo?.marca ?? "",
      modelo: vehiculo?.modelo ?? "",
      placa: vehiculo?.placa ?? vehiculo?.chasis,
      finstalacion: dayjs(vehiculo?.device_data.created_at).format(
        "DD-MM-YYYY"
      ),
      fvencimiento: dayjs(vehiculo?.device_data.expiration_date).format(
        "DD-MM-YYYY"
      ),
      email: fieldCorreoCertificado,
    };*/

    try {
      setEstadoEnvioCertificado("enviando");
      const response = await fetch("https://hooks.zapier.com/hooks/catch/4191687/228k6v7/", {
        method: "POST",
        
        body: formData,
      });
      console.log(response)

      if (!response.ok) {
        NotificationManager.error(
          "Ha habido un error generando el certificado",
          "Error"
        );
        setEstadoEnvioCertificado(undefined);
        throw new Error("Error al realizar la solicitud");
        //setEstadoEnvioCertificado("enviando")
      }
      if (response.status === 200) {
        setTimeout(() => {
          setEstadoEnvioCertificado(undefined);
          setOpenModal(undefined);
          NotificationManager.success(
            "El certificado ha sido generado y enviado",
            "Certificado enviado"
          );
          setFieldCorreoCertificado("");
        }, 700);
      }
    } catch (error) {
      console.error("Se produjo un error:", error);
    }finally{
      setEstadoEnvioCertificado(undefined);
    }
  };

  const compartirUbicacion = () => {
    const url = new ClipboardItem({
      "text/plain": API.post(apiName, "/sharing", {
        body: {
          active: 1,
          name:
            vehiculo?.device_data.plate_number! +
            Math.trunc(Math.random() * (999 - 100) + 100),
          expiration_date: dayjs(new Date())
            .tz("America/Guayaquil")
            .add(24, "hour")
            .format("YYYY-MM-DD HH:mm:ss"),
          delete_after_expiration: 1,
          devices: [vehiculo?.id],
          sesionId: user?.sessionId,
        },
      })
        .then((res) => new Blob([res.sharing_url], { type: "text/plain" }))
        .catch(
          (err) => new Blob(["error al obtener la url"], { type: "text/plain" })
        ),
    });

    navigator.clipboard
      .write([url])
      .then((res) => {
        NotificationManager.success(
          "La URL con la ubicación se ha copiado al portapapeles",
          "Ubicación Compartida"
        );
      })
      .catch((err) => {
        console.error(err);
        NotificationManager.error(
          "Error al copiar en el portapapeles",
          "Error",
          2000,
          () => {}
        );
      });
  };

  const guardarRC = async () => {
    try {
      setGuardando(true);
      let resultS3 = null;
      console.log(operacionRC);
      if (operacionRC.tipo !== "") {
        if (operacionRC.comprobante) {
          console.log(operacionRC.comprobante);

          try {
            resultS3 = await Storage.put(
              operacionRC.comprobante.name,
              operacionRC.comprobante,
              {
                level: "public",
                contentType: operacionRC.comprobante.type,
              }
            );
          } catch (error) {
            alert("No se ha podido subir el archivo");
            return;
          }
        }

        const resultAPI = await API.post(apiName, "/renovaciones", {
          body: {
            operation: operacionRC.tipo,
            data: {
              sim_number: vehiculo?.device_data.sim_number,
              imei: vehiculo?.imei,
              name: vehiculo?.name,
              dateExpire: operacionRC.fecha,
              solicitudId: vehiculo?.solicitud_id,
              device_id: vehiculo?.id,
              fileName: resultS3 !== null ? resultS3.key : undefined,
              numFactura: operacionRC.factura,
              notas: operacionRC.notas,
              cliente:
                clienteSeleccionado?.nombres +
                " " +
                clienteSeleccionado?.apellidos,

              sesionId: user?.sessionId,
            },
          },
        });
        console.log(resultAPI);

        alert(`La ${operacionRC.tipo} se realizo con exito`);

        setOperacionRC({
          tipo: "",
          fecha: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
          factura: "",
          comprobante: null,
          notas: "",
        });
        setOpenModal(undefined);
        setGuardando(false);
      }
    } catch (error) {
      console.log(error)
      alert(`La ${operacionRC.tipo} no se pudo procesar`);
    }
  };

  const updateCliente = async () => {
    setLoading("cliente");
    try {
      const result = await API.put(apiName, "/usuarios", {
        body: {
          operation: "update",
          data: {
            email: emailEdit,
            telefono: telefonoEdit,
            id: clienteSeleccionado?.id,
            freshworksId: clienteSeleccionado?.freshworks_id,
            sesionId: user?.sessionId,
          },
        },
      });
      NotificationManager.success(
        "El usuario ha sido actualizado",
        "Cambio Realizado"
      );
      console.log(result);
      setLoading("");
      clienteSeleccionado &&
        onSetCliente({
          ...clienteSeleccionado,
          email: emailEdit,
          telefono_1: telefonoEdit,
        });
      setCookie(
        "cliente",
        { ...clienteSeleccionado, email: emailEdit, telefono_1: telefonoEdit },
        { maxAge: 86400 }
      );
      setEditarEmail(false);
      setEditarNumero(false);
    } catch (error) {
      NotificationManager.error(
        "No se pudo actualizar el usuario",
        "Error",
        2000,
        () => {}
      );
      console.log(error);
      setLoading("");
    }
  };
  const updateVehiculo = async () => {
    setLoading("infovehiculo");
    try {
      const result = await API.put(apiName, "/vehiculos", {
        body: {
          imei: vehiculo?.imei,
          id: vehiculo?.id,
          vehiculo_id: vehiculo?.vehiculo_id,
          placa: placaEdit,
          chasis: chasisEdit,
          name: nombreEdit,
          deviceModel: vehiculo?.device_data.device_model,
          freshworksId: vehiculo?.freshworks_id,
          sesionId: user?.sessionId,
        },
      });
      NotificationManager.success(
        "El vehículo ha sido actualizado",
        "Cambio Realizado"
      );
      console.log(result);
      setLoading("");
      clienteSeleccionado &&
        onSetCliente({ ...clienteSeleccionado, email: emailEdit });
      // setCookie('cliente', { ...clienteSeleccionado, email: emailEdit }, { maxAge: 86400 })
      setEditarVehiculo(false);
    } catch (error) {
      NotificationManager.error(
        "No se pudo actualizar el vehículo",
        "Error",
        2000,
        () => {}
      );
      console.error(error);
      setLoading("");
    }
  };

  const onHabilitarRevision = async () => {
    try {
      const result = await API.patch(apiName, "/vehiculos", {
        body: {
          operation: "habilitar",
          device_id: vehiculo?.id,
          sessionId: user?.sessionId,
        },
      });
      console.log(`HABILITAR REVISION: ${result.clave}`)
      NotificationManager.success(
        
        "La revisión ha sido habilitada y solo el tecnico puede finalizar este proceso.",`Revisión habilitada CLAVE:${result.clave}`,10000
      );
    } catch (error) {
      NotificationManager.error(
        "No se pudo Habilitar la revision",
        "Error",
        2000,
        () => {}
      );
      console.error(error);
    }
  };

  useEffect(() => {
    const getVehiculos = async () => {
      console.log("cliente seleccionado", clienteSeleccionado);
      try {
        setLoading("vehiculos");
        const result = await API.get(
          apiName,
          `/gpswox_clientes/${clienteSeleccionado?.id}/vehiculos`,
          {}
        );
        setLoading("");
         console.log("vehiculos-data", result.data);
        const vehiculos = [];
        for (let i = 0; i < result.length; i++) {
          vehiculos.push({
            id: result[i].id,
            vehiculo_id: result[i].vehiculo_id,
            name: result[i].name,
            marca: "",
            modelo: "",
            anio: 0,
            lat: 0,
            lng: 0,
            course: 0,
            speed: "",
            time: "",
            tail: [],
            device_data: {
              plate_number: result[i].plate_number,
              device_model: result[i].device_model,
              sim_number: result[i].sim_number,
            },
            bloqueado: 0,
            empresa_bloqueo: "",
            persona_bloqueo: "",
            contacto_bloqueo: "",
          });
        }
        console.log(vehiculos);
        setVehiculos(vehiculos);
      } catch (error) {
        console.log(error);
        setLoading("");
      }
    };
    clienteSeleccionado && getVehiculos();
  }, [clienteSeleccionado]);

  useEffect(() => {
    if (
      vehiculo &&
      vehiculoSeleccionado &&
      vehiculo.id !== vehiculoSeleccionado.id
    ) {
      setVehiculo(undefined);
    }
    const getVehiculo = async () => {
      try {
        const result = await API.get(
          apiName,
          `/gpswox_vehiculos/${vehiculoSeleccionado?.id}`,
          {}
        );
        console.log("Vehiculos polling", result);
        setVehiculo(result);
        setNombreEdit(result?.name);
        setPlacaEdit(result?.placa);
        setChasisEdit(result?.chasis);
      } catch (error) {
        setLoading("");
      }
    };
    if (vehiculoSeleccionado) {
      getVehiculo();
      const timer = setInterval(() => getVehiculo(), 10000);
      return () => clearInterval(timer);
    }
  }, [vehiculoSeleccionado]);

  useEffect(() => {
    if (clienteSeleccionado) {
      setEmailEdit(clienteSeleccionado.email);
      setTelefonoEdit(clienteSeleccionado.telefono_1);
    }
  }, []);

  const onEnviarComando = async (operation: string) => {
    if (vehiculo) {
      setLoading(operation);
      const result = await API.post(apiName, "/emnify_devices/sms", {
        body: {
          vehiculo: {
            ...vehiculo,
            device_data: {
              sim_number: vehiculo.device_data.sim_number,
            },
          },
          dispositivo: {
            marca: vehiculo.marca_dispositivo,
            modelo: vehiculo.modelo_dispositivo,
            device_model: vehiculo.device_data.device_model,
          },
          operation,
          sesionId: user?.sessionId,
        },
      });
      NotificationManager.success(
        `El comando para "${operation.toLocaleUpperCase()}" se está procesando`,
        "Comando Enviado"
      );
      await makePause();
      setLoading("");
    }
  };

  const onCrearTicket = async () => {
    if (vehiculo) {
      setLoading("visita");
      const result = await API.post(apiName, "/vehiculos", {
        body: {
          device_id: vehiculo.id,
          placa: vehiculo.device_data.plate_number,
          solicitudId: vehiculo.solicitud_id,
          emailCliente: clienteSeleccionado?.email,
          bodyMail,
          username: user?.username,
          sessionId: user?.sessionId,
        },
      });
      setBodyMail("");
      NotificationManager.info("Se ha creado el ticket");
      console.log(result);
      // setSms(result.data);
      setLoading("");
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const validarCorreos = (correos: string): boolean => {
    const correoRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const listaCorreos = correos.split(",").map((correo) => correo.trim());

    for (let i = 0; i < listaCorreos.length; i++) {
      if (!correoRegex.test(listaCorreos[i])) {
        return false;
      }
    }

    return true;
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://gkjhefy1o9.execute-api.us-east-1.amazonaws.com/contifico/lista_renovaciones",
          {
            method: "GET",
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          setProdRenovSeleccionada(responseData[0]['id'])
          
          setProductosRenovacion(responseData);
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    if (factAutoContifico) {
      fetchData();
    }
  }, [factAutoContifico]);

  const realizarFactura = async () => {
    setGuardando(true);
    try {
      const responseNumeroFactura = await axios.get("https://gkjhefy1o9.execute-api.us-east-1.amazonaws.com/contifico/numero_factura");
      if (responseNumeroFactura.status !== 200) {
        console.log('Error al obtener el numero de factura')
        NotificationManager.error(`Obteniendo el numero de factura `, 'Error', 3000, () => {});
        return;
      }
      const numeroFactura =  responseNumeroFactura.data.numeroFactura; 
      console.log(numeroFactura) 
      const subtotal_12 = +(Number(valorRenovacion) / 1.15).toFixed(2);
      const responseFactura = await axios.post("https://gkjhefy1o9.execute-api.us-east-1.amazonaws.com/contifico/nueva_factura", {
        tipo_documento: "FAC",
        documento: numeroFactura,
        estado: "C",
        electronico: true,
        autorizacion: "",
        caja_id: undefined,
        cliente: {
          ruc: "",
          cedula: clienteSeleccionado?.ciruc,
          razon_social: `${clienteSeleccionado?.nombres} ${clienteSeleccionado?.apellidos}`,
          tipo: "N",
          es_extranjero: undefined,
        },
        vendedor: {
          cedula: ciVendedor,
          razon_social: nombreVendedor,
          tipo: "N",
        },
        descripcion: `Contrato ${vehiculo?.solicitud_id}. Placa ${vehiculo?.placa}`,
        subtotal_0: 0,
        subtotal_12,
        ice: 0,
        iva: +(subtotal_12 * 0.15).toFixed(2),
        servicio: 0,
        total: valorRenovacion,
        detalles: [
          {
            producto_id: `${prodRenovSeleccionada}`,
            cantidad: 1,
            precio: subtotal_12,
            porcentaje_iva: 15,
            porcentaje_descuento: 0,
            base_cero: 0,
            base_gravable: subtotal_12,
            base_no_gravable: 0,
            porcentaje_ice: 0,
            valor_ice: 0,
          },
        ],
      }, {});
  

      // Verificar el resultado de la factura
      if (responseFactura.status===400 ||  responseFactura.status !== 200 || ('error') in responseFactura.data) {
        NotificationManager.error(`Detalles: ${responseFactura.data.error} `, 'Error', 3000, () => {});
        console.log(responseFactura)
        console.log('factura fallo')
        setGuardando(false);
        
      }else{        
        console.log("Facturación exitosa:", responseFactura.data);
        setGuardando(false);
        setCiVendedor('')   
        guardarRC()
      }        
     
    } catch (error) {
      NotificationManager.error(`Detalles: ${error}`, 'Error', 3000, () => {});
      console.error("Error al realizar la factura:", error);
      setGuardando(false);
      
    }
  };

  console.log('variable vehiculo')
  console.log(vehiculo)

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#c9c9c9",
          height: 50,
        }}
      >
        <button className="drawer-btn" onClick={toggleDrawer}>
          ≡
        </button>
        <h2
          onClick={() => {
            NotificationManager.warning(user?.username, "Usuario", 1000);
          }}
        >
          Soporte Navitrac{" "}
          {process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV} -{" "}
          {user?.username}
        </h2>
        <button
          onClick={() => {
            setOpenModal("finalizar");
          }}
        >
          Finalizar
        </button>
      </div>

      {/* <button className="drawer-btn" onClick={toggleDrawer}>
        Vehículos
      </button> */}
      <div
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflow: "auto",
        }}
      >
        <div className="search">
          <div style={{ padding: 10 }}>
            <p>Selecciona un vehículo para acceder a sus funciones </p>
            <input
              value={filterVehiculos}
              onChange={(e) => setFilterVehiculos(e.target.value)}
              placeholder={"Buscar..."}
            />
            <label>Vehiculos</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              overflowX: "hidden",
              width: "100%",
            }}
          >
            {loading === "vehiculos" ? (
              <ClipLoader />
            ) : (
              vehiculos
                .filter(
                  (v) =>
                    v.name
                      .toLowerCase()
                      .includes(filterVehiculos.toLowerCase()) ||
                    v.device_data.plate_number
                      .toLowerCase()
                      .includes(filterVehiculos.toLowerCase())
                )
                .map((v) => (
                  <button
                    style={
                      vehiculoSeleccionado?.id === v.id
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#6bc97b",
                            color: "white",
                            padding: 5,
                            fontSize: "1rem",
                          }
                        : {
                            display: "flex",
                            flexDirection: "column",
                            padding: 5,
                            fontSize: "1rem",
                          }
                    }
                    className="itemList"
                    key={v.id}
                    disabled={v.device_data.sim_number === "" ? true : false}
                    onClick={() => {
                      setVehiculoSeleccionado(v);
                      setEditarVehiculo(false);
                    }}
                  >
                    <span>{v.name}</span>
                    <span style={{ fontSize: 10 }}>
                      {v.device_data.plate_number}
                    </span>
                  </button>
                ))
            )}
          </div>
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          enableOverlay={false}
          style={{ marginTop: 50, width: "70%" }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div style={{ padding: 10 }}>
              <p>Selecciona un vehículo para acceder a sus funciones </p>
              <input
                value={filterVehiculos}
                onChange={(e) => setFilterVehiculos(e.target.value)}
                placeholder={"Buscar..."}
              />
              <label>Vehiculos</label>
            </div>
            <div
              style={{
                overflowY: "scroll",
                overflowX: "hidden",
                width: "100%",
              }}
            >
              {loading === "vehiculos" ? (
                <ClipLoader />
              ) : (
                vehiculos
                  .filter(
                    (v) =>
                      v.name
                        .toLowerCase()
                        .includes(filterVehiculos.toLowerCase()) ||
                      v.device_data.plate_number
                        .toLowerCase()
                        .includes(filterVehiculos.toLowerCase())
                  )
                  .map((v) => (
                    <button
                      style={
                        vehiculoSeleccionado?.id === v.id
                          ? {
                              backgroundColor: "#6bc97b",
                              color: "white",
                              padding: 5,
                              fontSize: "1rem",
                            }
                          : { padding: 10, fontSize: "1rem" }
                      }
                      className="itemList"
                      key={v.id}
                      disabled={v.device_data.sim_number === "" ? true : false}
                      onClick={() => {
                        setVehiculoSeleccionado(v);
                        setEditarVehiculo(false);
                      }}
                    >
                      {v.name}
                    </button>
                  ))
              )}
            </div>
          </div>
        </Drawer>
        <div style={{ display: "flex", flexDirection: "column", width: "70%" }}>
          <div
            className="car-info"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "60%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                {/* <label>{clienteSeleccionado?.id}</label> */}
                <input
                  value={emailEdit}
                  onChange={(e) => {
                    setEmailEdit(e.target.value);
                  }}
                  disabled={!editarEmail}
                />
                {editarEmail && (
                  <>
                    <button
                      onClick={() => {
                        setEditarEmail(false);
                      }}
                    >
                      Cancelar
                    </button>
                    <button
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => updateCliente()}
                    >
                      Guardar
                    </button>
                  </>
                )}
                {!editarEmail && (
                  <button
                    onClick={() => {
                      setEditarEmail(true);
                    }}
                  >
                    Editar
                  </button>
                )}
              </div>
              {loading === "vehiculo" ? (
                <ClipLoader />
              ) : (
                vehiculo && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "0.25rem",
                    }}
                  >
                    <div>
                      <label>
                        <span>Solicitud:</span> {vehiculo?.solicitud_id}
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <span>Nombre:</span>
                      <input
                        value={nombreEdit}
                        onChange={(e) => {
                          setNombreEdit(e.target.value);
                        }}
                        disabled={!editarVehiculo}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      <span>Placa:</span>
                      <input
                        value={placaEdit}
                        onChange={(e) => {
                          setPlacaEdit(e.target.value);
                        }}
                        disabled={!editarVehiculo}
                      />
                      <span>Chasis:</span>
                      <input
                        value={chasisEdit}
                        onChange={(e) => {
                          setChasisEdit(e.target.value);
                        }}
                        disabled={!editarVehiculo}
                      />
                      {editarVehiculo && (
                        <>
                          <button
                            onClick={() => {
                              setEditarVehiculo(false);
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            style={{ backgroundColor: "red", color: "white" }}
                            onClick={updateVehiculo}
                          >
                            Guardar
                          </button>
                        </>
                      )}
                      {!editarVehiculo && (
                        <button
                          onClick={() => {
                            setEditarVehiculo(true);
                          }}
                        >
                          Editar
                        </button>
                      )}
                    </div>
                    <div>
                      <label>
                        <span>Marca:</span> {vehiculo?.marca}{" "}
                        <span>Modelo: </span>
                        {vehiculo?.modelo} {vehiculo?.anio}
                      </label>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <label>
                        <span>Fecha Vencimiento:</span>{" "}
                        {dayjs(vehiculo?.device_data.expiration_date).format(
                          "DD-MM-YYYY"
                        )}
                      </label>
                      <button onClick={() => setOpenModal("renovaciones")}>
                        Renovar/Cancelar
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <label>
                        <span>Dispositivo:</span> {vehiculo?.marca_dispositivo}{" "}
                        {vehiculo?.modelo_dispositivo}
                      </label>
                      <label>
                        <span>Velocidad:</span> {vehiculo?.speed} km/h
                      </label>
                    </div>
                    <div>
                      <label>
                        <span>Última conexión:</span> {""}
                        {/* {!isNaN(new Date(vehiculo.time).getTime())
                          ? dayjs(vehiculo?.time).format("DD-MM-YYYY HH:mm:ss")
                          : vehiculo?.time} */}
                        {vehiculo?.time === "Expirado" ? (
                          <span style={{ color: "red" }}>{vehiculo?.time}</span>
                        ) : (
                          vehiculo?.time
                        )}{" "}
                        {/** por ahora esta fecha viene con el formato correcto desde el servidor */}
                      </label>
                    </div>
                  </div>
                )
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "35%",
                borderLeft: "solid 2px lightgray",
                borderRight: "solid 2px lightgray",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  margin: "0 5px",
                }}
              >
                <label>Número:</label>
                <input
                  value={telefonoEdit}
                  onChange={(e) => {
                    setTelefonoEdit(e.target.value);
                  }}
                  disabled={!editarNumero}
                />

                <button
                  onClick={() => {
                    setEditarNumero(!editarNumero);
                    setTelefonoEdit(clienteSeleccionado?.telefono_1!);
                  }}
                >
                  {!editarNumero ? "Editar" : "Cancelar"}
                </button>
                {editarNumero && (
                  <button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={() => updateCliente()}
                  >
                    Guardar
                  </button>
                )}
              </div>
              {vehiculo?.time !== "Expirado" ? (
                vehiculo?.sensors?.map((sensor) => (
                  <div key={sensor.id} style={{ backgroundColor: "white" }}>
                    <label>{sensor.name}:</label>
                    <label> {sensor.value}</label>
                  </div>
                ))
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label style={{ fontWeight: "bold" }}>Cancelaciones</label>
                  {vehiculo?.cancelaciones?.map((cancelacion, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label>
                        Fecha: {dayjs(cancelacion.fecha).format("DD-MM-YYYY")}
                      </label>
                      <label>Notas: {cancelacion.problema}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {vehiculo?.bloqueado === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  gap: "0.3rem",
                  width: "20%",
                }}
              >
                <BotonComando
                  item={{ vehiculo_id: vehiculo.id, command: "bloquear" }}
                  onClick={() => onEnviarComando("bloquear_motor_sms")}
                >
                  Bloquear
                </BotonComando>
                <BotonComando
                  item={{ vehiculo_id: vehiculo.id, command: "desbloquear" }}
                  onClick={() => onEnviarComando("desbloquear_motor_sms")}
                >
                  Desbloquear
                </BotonComando>
                {(vehiculo?.device_data.device_model.includes("BRAND01") ||
                  vehiculo?.device_data.device_model.includes("BRAND03")) && (
                  <BotonComando
                    item={{ vehiculo_id: vehiculo.id, command: "puertas" }}
                    onClick={() => onEnviarComando("abrir_puertas_sms")}
                  >
                    Abrir Puertas
                  </BotonComando>
                )}
                {(vehiculo?.marca_dispositivo?.includes("SEEWORLD") ||
                  vehiculo?.marca_dispositivo?.includes("CONCOX")) && (
                  <BotonComando
                    item={{ vehiculo_id: vehiculo.id, command: "center" }}
                    onClick={() => onEnviarComando("comando_verificar")}
                  >
                    Enviar Center
                  </BotonComando>
                )}

                {/* <BotonComando
                  item={{ vehiculo_id: vehiculo.id, command: "reiniciar" }}
                  onClick={() => onEnviarComando("comando_reiniciar_sms")}
                >
                  Reiniciar
                </BotonComando> */}

                {/* <BotonComando
                  item={{ vehiculo_id: vehiculo.id, command: "status" }}
                  onClick={() => onEnviarComando("status")}
                >
                  Status
                </BotonComando> */}
                {/* <button onClick={onUbicacion}>Ubicacion</button> */}

                <button onClick={() => setOpenModal("ticket")}>
                  Crear Ticket
                </button>
                <button onClick={() => setOpenModal("certificado")}>
                  Generar certificado
                </button>
                <button onClick={() => setOpenModal("ubicacion")}>
                  Compartir Ubicacion
                </button>
                {vehiculo.revision_habilitada === null &&
                  vehiculo.estado_instalacion === "instalado" && (
                    <span className="disabled-btn">Pendiente Finalizar</span>
                  )}
                {vehiculo.revision_habilitada === null &&
                  vehiculo.estado_instalacion === "finalizado" && (
                    <button onClick={() => setOpenModal("confirmar_revision")}>
                      Habilitar Revisión
                    </button>
                  )}
                {(vehiculo.revision_habilitada && vehiculo.revision_habilitada ==='si')  && (
                  <span className="disabled-btn">Revisión Habilitada</span>
                )}
              </div>
            )}
            {vehiculo?.bloqueado === 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  gap: "0.3rem",
                  maxWidth: "30%",
                }}
              >
                <p style={{ color: "red", fontWeight: "bold", fontSize: 16 }}>
                  Este vehículo ha sido bloqueado por orden de la empresa{" "}
                  {vehiculo.empresa_bloqueo}. Contactarse con{" "}
                  {vehiculo.persona_bloqueo} al {vehiculo.contacto_bloqueo}.
                </p>
              </div>
            )}
          </div>

          <div className="map">
            <MapContainer
              center={
                vehiculo
                  ? [vehiculo.lat, vehiculo.lng]
                  : [-2.902328, -79.0040384]
              }
              zoom={13}
              style={{ width: "100%", height: "100%", zIndex: 0 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {vehiculo && (
                <Marker
                  position={[vehiculo.lat, vehiculo.lng]}
                  icon={divIcon(vehiculo.course)}
                />
              )}
              {vehiculo && (
                <RecenterAutomatically
                  position={{ lat: vehiculo.lat, lng: vehiculo.lng }}
                />
              )}
              {vehiculo && <Polyline positions={vehiculo.tail} />}
            </MapContainer>
          </div>
        </div>
        {vehiculo && (
          <ConsolaSMS sim_number={vehiculo.device_data.sim_number} />
        )}
      </div>

      <Modal
        isOpen={openModal === "ubicacion"}
        style={{
          content: {
            zIndex: 3,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
              width: "100%",
              maxWidth: "30rem",
              textAlign: "justify",
            }}
          >
            {/* <h3>
              Está próximo a compartir un link mediante WhatsApp y correo
              electrónico con la ubicación del cliente y el número registrado.
              Se debe confirmar los datos antes de continuar
            </h3> */}
            <h3>
              Link con la ubicación se copiará en el portapapeles para que se pueda compartir con el solicitante.
            </h3>

            <h2>¿Está seguro de continuar?</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "25vh",
            }}
          >
            <button
              style={{ paddingInline: "1rem" }}
              onClick={() => {
                compartirUbicacion();
                setOpenModal(undefined);
              }}
            >
              SÍ
            </button>
            <button
              style={{ paddingInline: "1rem" }}
              onClick={() => {
                setOpenModal(undefined);
              }}
            >
              NO
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={openModal === "ticket"} style={{ content: { zIndex: 3 } }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
              width: "100%",
              maxWidth: "30rem",
            }}
          >
            <h1 style={{ fontSize: "1.5rem" }}>
              Vehiculo: {vehiculo?.device_data.plate_number}
            </h1>
            <h2>Número de instalación: {vehiculo?.solicitud_id}</h2>
            <textarea
              placeholder="Especifique aquí el requerimiento"
              style={{ width: "90%", height: "300px" }}
              value={bodyMail}
              onChange={(event) => setBodyMail(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "25vh",
            }}
          >
            <button
              onClick={() => {
                setOpenModal(undefined);
                setBodyMail("");
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                onCrearTicket();
                setOpenModal(undefined);
              }}
              disabled={bodyMail.trim() === "" ? true : false}
            >
              Continuar
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModal === "finalizar"}
        style={{ content: { zIndex: 3 } }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
              width: "100%",
              maxWidth: "30rem",
            }}
          >
            <h2>¿Cuál fue el motivo de la llamada?</h2>
            <textarea
              placeholder="Especifique aquí el problema"
              maxLength={200}
              style={{ width: "90%", height: "100px" }}
              value={fieldProblema}
              onChange={(event) => setFieldProblema(event.target.value)}
            />
            <h2>¿Cómo se solucionó?</h2>
            <textarea
              placeholder="Especifique aquí la solución"
              maxLength={200}
              style={{ width: "90%", height: "100px" }}
              value={fieldSolucion}
              onChange={(event) => setFieldSolucion(event.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              width: "25vh",
            }}
          >
            <button
              onClick={() => {
                setOpenModal(undefined);
                setFieldProblema("");
                setFieldSolucion("");
              }}
            >
              Cancelar
            </button>
            <button
              onClick={async () => {
                try {
                  await API.put(apiName, "/usuarios", {
                    body: {
                      operation: "exit_account",
                      data: {
                        userId: clienteSeleccionado?.id,
                        sesionId: user?.sessionId,
                        problema: fieldProblema,
                        solucion: fieldSolucion,
                      },
                    },
                  });
                } catch (error) {
                  console.error(error);
                }
                setFieldProblema("");
                setFieldSolucion("");
                removeCookie("cliente");
                navigate(-1);
                setOpenModal(undefined);
              }}
              disabled={
                fieldProblema.trim() === "" || fieldSolucion.trim() === ""
                  ? true
                  : false
              }
            >
              Enviar
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModal === "renovaciones"}
        style={{ content: { zIndex: 3 } }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "50%",
                maxWidth: factAutoContifico ? "1200px" : "500px",
                flex: "1",
                marginLeft: "20px",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Renovación</h2>
              <h3>Seleccione la Operación</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <input
                    type="radio"
                    id="renovacion"
                    name="renovacionCancelacion"
                    value="renovacion"
                    onChange={(e) =>
                      setOperacionRC((prevState) => ({
                        ...prevState,
                        tipo: e.target.value,
                      }))
                    }
                  />
                  <label htmlFor="renovacion">Renovación</label>
                </div>

                <div
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <input
                    type="radio"
                    id="cancelacion"
                    name="renovacionCancelacion"
                    value="cancelacion"
                    onChange={(e) =>
                      setOperacionRC((prevState) => ({
                        ...prevState,
                        tipo: e.target.value,
                        fecha: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
                      }))
                    }
                  />
                  <label htmlFor="cancelación">Cancelación</label>
                </div>
              </div>

              <h3>Fecha de Vencimiento</h3>
              <DateTimePicker
                name="fechaOperacion"
                clearIcon={null}
                value={new Date(operacionRC.fecha)}
                format="dd-MM-y HH:mm "
                minDate={new Date()}
                disabled={operacionRC.tipo !== "renovacion"}
                onChange={(e) =>
                  setOperacionRC((prevState) => ({
                    ...prevState,
                    fecha: dayjs(e).format("YYYY-MM-DDTHH:mm"),
                  }))
                }
              />

              <h3>Número de la Factura</h3>
              <input
                style={{ border: "solid 1px gray", width: "320px" }}
                placeholder="Número de Factura"
                id="numeroFactura"
                value={operacionRC.factura}
                onChange={(e) =>
                  setOperacionRC((prevState) => ({
                    ...prevState,
                    factura: e.target.value,
                  }))
                }
              />
              <h3>Facturación Automatica</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <input
                  style={{ width: "20px" }}
                  type="checkbox"
                  id="miCheckbox"
                  name="miCheckbox"
                  checked={factAutoContifico}
                  onChange={() => setFactAutoContifico(!factAutoContifico)}
                />
                <label htmlFor="miCheckbox">Facturar</label>
              </div>

              <h3>Subir el Comprobante de Pago</h3>
              <input
                type="file"
                id="comprobante"
                name="comprobante"
                accept="image/*, application/pdf"
                onChange={(e) => getComprobanteRC(e.target.files!)}
              />
            </div>
            {factAutoContifico && (
              <div style={{ width: "50%", flex: "1" }}>
                <h2 style={{ textAlign: "center" }}>Facturación</h2>
                <h3>Seleccione el servicio</h3>
                <select
                  id="miSelect"
                  value={prodRenovSeleccionada}
                  onChange={(e) => {setProdRenovSeleccionada(e.target.value); console.log(e.target.value)}}
                >
                  {productosRenovacion.map((producto) => (
                    <option key={producto.id} value={producto.id}>
                      {producto.nombre}
                    </option>
                  ))}
                </select>
                <h3>Nombre Vendedor</h3>
                <input
                  style={{ border: "1px solid gray", maxWidth: "315px" }}
                  value={nombreVendedor}
                  onChange={(e) => {
                    setNombreVendedor(e.target.value.toUpperCase());
                  }}
                />
                <h3>CI Vendedor</h3>
                <input
                  style={{ border: "1px solid gray", maxWidth: "315px" }}
                  value={ciVendedor}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // Validar si el nuevo valor contiene solo números
                    if (/^\d*$/.test(newValue)) {
                      // Actualizar el estado solo si el nuevo valor es válido
                      setCiVendedor(newValue);
                    }
                  }}
                />
                <h3>Valor (con iva)</h3>
                <input
                  style={{ border: "1px solid gray", maxWidth: "315px" }}
                  value={valorRenovacion}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    // Validar si el nuevo valor cumple con el formato de cantidad de dinero
                    if (/^(\d+(\.\d{0,2})?)?$/.test(newValue)) {
                      // Actualizar el estado solo si el nuevo valor es válido
                      setValorRenovacion(newValue);
                    }
                  }}
                />
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <br />
            <h2>Notas:</h2>
            <br />
            <textarea
              placeholder="Notas..."
              style={{
                minWidth: 300,
                minHeight: 100,
                maxWidth: 400,
                marginBottom: 20,
              }}
              onChange={(e) =>
                setOperacionRC((prevState) => ({
                  ...prevState,
                  notas: e.target.value,
                }))
              }
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                width: "20%",
              }}
            >
              {!guardando ? (
                <>
                  <button
                    onClick={() => {
                      setOpenModal(undefined);
                      setOperacionRC({
                        tipo: "",
                        fecha: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
                        factura: "",
                        comprobante: null,
                        notas: "",
                      });
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{ backgroundColor: "red", color: "white" }}
                    onClick={ () => {
                      if(factAutoContifico){
                         realizarFactura();
          
                      }else{
                        guardarRC();                      
                      }                     
                    }}
                    disabled={operacionRC.tipo === ""}
                  >
                    Guardar
                  </button>
                </>
              ) : (
                <ClipLoader />
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openModal === "confirmar_revision"}
        style={{
          content: {
            zIndex: 3,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <h1 style={{ textTransform: "capitalize" }}>Habilitar Revisión</h1>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          ¿Estás seguro de realizar esta acción?
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            onClick={() => {
              onHabilitarRevision();
              setOpenModal(undefined);
            }}
          >
            Aceptar
          </button>

          <button onClick={() => setOpenModal(undefined)}>Cancelar</button>
        </div>
      </Modal>
      <Modal
        isOpen={openModal === "confirmar_revision"}
        style={{
          content: {
            zIndex: 3,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <h1 style={{ textTransform: "capitalize" }}>Habilitar Revisión</h1>
        <div
          style={{
            marginBottom: 10,
          }}
        >
          ¿Estás seguro de realizar esta acción?
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button
            onClick={() => {
              onHabilitarRevision();
              setOpenModal(undefined);
            }}
          >
            Aceptar
          </button>
          <button onClick={() => setOpenModal(undefined)}>Cancelar</button>
        </div>
      </Modal>
      <Modal
        isOpen={openModal === "certificado"}
        style={{
          content: {
            zIndex: 3,
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <h1>Generar Certificado de Servicio Navitrac</h1>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <p>Ingrese los correos electronicos</p>
          <input
            style={{ border: "1px solid gray" }}
            type="text"
            placeholder="email1@example.com,email2@example.com"
            value={fieldCorreoCertificado}
            onChange={(event) => setFieldCorreoCertificado(event.target.value)}
          ></input>
          {estadoEnvioCertificado === "enviando" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClipLoader />
            </div>
          )}
        </div>
        <div> 
        
        </div>
        <div style={{ marginBottom:"20px", display: "flex", justifyContent: "space-around" }}>
          <button
            onClick={() => {
              if (validarCorreos(fieldCorreoCertificado)) {
                console.log("enviarrrrr")
                generarCertificado();
              } else {
                NotificationManager.error("Correos no validos", "Error");
              }
            }}
          >
            Generar y enviar
          </button>
          <button onClick={() => setOpenModal(undefined)}>Cancelar</button>
        </div>

        <PDFViewer ref={viewerRef} width="100%" height="400">
        <PdfDocument ciruc={clienteSeleccionado?.ciruc} cliente={`${clienteSeleccionado?.nombres} ${clienteSeleccionado?.apellidos}`} marca={vehiculo?.marca ?? ""} modelo= {vehiculo?.modelo ?? ""} placa={vehiculo?.placa ?? vehiculo?.chasis} finst={dayjs(vehiculo?.device_data.created_at).format("DD-MM-YYYY")} fvenc={dayjs(vehiculo?.device_data.expiration_date).format("DD-MM-YYYY")} />
      </PDFViewer>
      </Modal>
    </div>
  );
};

export default Main;

const RecenterAutomatically = ({ position }: { position: IPosition }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([position.lat, position.lng]);
  }, [position]);
  return null;
};
