const aws_params = {
    Auth: {
        identityPoolId: 'us-east-1:9d11a78d-dc45-488b-aff1-608281f298e2', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        // userPoolId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito User Pool ID
        // userPoolWebClientId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito Web Client ID
        RoleArn: 'arn:aws:iam::458802466372:role/lambda-executioner',
        AccountId: '458802466372', // your AWS account ID
    },
    Storage: {
        AWSS3: {
            bucket: 'assests-navitrac', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-2', //OPTIONAL -  Amazon service region
        }
    },
    API: {
        endpoints: [
            {
                name: "soporteNavitrac-dev",
                endpoint: "https://gkjhefy1o9.execute-api.us-east-1.amazonaws.com",
                region: "us-east-1"
            }
        ]
    }
}
export default aws_params;