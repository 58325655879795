import { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import dayjs from "dayjs";
import { API } from "aws-amplify";

const apiName = `soporteNavitrac-${process.env.REACT_APP_ENV}`;

const Main = (props: { sim_number: string }) => {
    const [loading, setLoading] = useState("");
    const [sms, setSms] = useState<any[]>([]);
    useEffect(() => {
        
        const getMensajes = async () => {
            try {
                setLoading("mensajes")
                const result = await API.get(apiName, `/emnify_devices/${props.sim_number}/sms`, {});;
                setSms(result);
                console.log("mensajes", result);
            } catch (error) {

            } finally {
                setLoading("");
            }
        };
        const poolingMensajes = async () => {
            try {
                const result = await API.get(apiName, `/emnify_devices/${props.sim_number}/sms`, {});;
                setSms(result);
                // console.log(sms)
                // console.log("mensajes", result);
            } catch (error) {
            }
        };
        getMensajes();
        const timer = setInterval(() => poolingMensajes(), 2000);
        return () => clearInterval(timer);
    }, []);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '15%'
        }}>
            <div style={{ padding: 10 }}>
                <label>SMS</label>
            </div>
            <div
                style={{ overflowY: "scroll", overflowX: "hidden", width: "100%" }}
            >
                {loading === "mensajes" ?
                    <ClipLoader />
                    :
                    sms.map((s, index) => (
                        <div
                            style={getMessageStyle(s.status.id, s.sms_type.description)}
                            key={index}
                        >
                            <span
                                style={{
                                    marginBottom: 5,
                                    fontWeight: "bold",
                                    wordWrap: "break-word",
                                }}
                            >
                                {s.payload}
                            </span>
                            {/* <span>{s.status.description}</span>
                                <span>{s.sms_type.description}</span> */}

                            {s.sms_type.description === "MT" && (
                                <span style={{ fontSize: 12 }}>
                                    Submit:{" "}
                                    {dayjs(s.submit_date).format("DD-MM-YY H:mm:ss A")}
                                </span>
                            )}
                            {s.status.id < 4 ? (
                                <div>
                                    <ClipLoader size={12} />
                                </div>
                            ) : (
                                <span style={{ fontSize: 12 }}>
                                    Delivery:{" "}
                                    {dayjs(s.delivery_date).format("DD-MM-YY H:mm:ss A")}
                                </span>
                            )}
                            {/* <span>date {s.final_date}</span> */}
                            <span
                                style={{
                                    fontSize: 14,
                                    textAlign: "left",
                                    marginBottom: "-0.5rem",
                                    marginTop: "0.5rem",
                                    fontWeight: "bolder",
                                }}
                            >
                                {s.sms_type.description.includes("MO")
                                    ? "Recibido"
                                    : s.status.id === 1
                                        ? "Pendiente"
                                        : s.status.id === 2
                                            ? "En Progreso"
                                            : s.status.id === 3
                                                ? "Pendiente"
                                                : s.status.id === 4
                                                    ? "Enviado"
                                                    : s.status.id === 5
                                                        ? "Fallido"
                                                        : s.status.id === 6
                                                            ? "Expirado"
                                                            : "Cancelado"}
                            </span>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Main;

const getMessageStyle = (status_id: number, sms_type: string) => {
    let style = {};
    if (sms_type.includes("MO")) {
        style = {
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#43657c",
            margin: "10px 0px",
            padding: "10px 2px",
            color: "white",
        };
        return style;
    }

    switch (status_id) {
        case 1:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "black",
            };
            break;
        case 2:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "whitesmoke",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "black",
            };
            break;
        case 3:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "whitesmoke",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "black",
            };
            break;
        case 4:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#6bc97b",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "white",
            };
            break;
        case 5:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "red",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "white",
            };
            break;
        case 6:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "orange",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "white",
            };
            break;
        case 7:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "gray",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "black",
            };
            break;
        default:
            style = {
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#b7b8bc",
                margin: "10px 0px",
                padding: "10px 2px",
                color: "white",
            };
            break;
    }
    return style;
};
