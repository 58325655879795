import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../../../state/auth/contex';
import './styles.css';

const apiName = `soporteNavitrac-${process.env.REACT_APP_ENV}`;

const Main = () => {
    const { user, onLogin } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['usuario']);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    useEffect(() => {
        const tryLogin = async () => {
            setLoading(true);
            const usuario = await cookies['usuario']
            console.log('user from cookies', usuario)
            if (usuario) {
                try {
                    const result = await API.post(apiName, '/login', {
                        body: {
                            operation: 'auth', data: { username: usuario.username, password: usuario.password }
                        }
                    });
                    if (result) {
                        setLoading(false);
                        onLogin({ username: usuario.username, sessionId: result.sesion_id });
                    }
                    else (
                        removeCookie('usuario')
                    )
                } catch (error) {
                    console.log("Error", error)
                }
            } else {
                // TODO
            }
            setLoading(false);
        }
        tryLogin();
    }, [])

    return (<div className='rootContainer'>
        <div className='boxContainer'>
            <h2>Iniciar Sesión</h2>
            {process.env.REACT_APP_ENV !== 'prod' && <p>{process.env.REACT_APP_ENV}</p>}
            <div className='formContainer'>
                <div className='inputContainer'>
                    <label htmlFor="">Usuario</label>
                    <input type="text" value={username} onChange={(e) => {
                        setErrorMessage(undefined);
                        setUsername(e.target.value);
                    }} />
                </div>
                <div className='inputContainer'>
                    <label htmlFor="">Contraseña</label>
                    <input type="password" value={password} onChange={(e) => {
                        setErrorMessage(undefined);
                        setPassword(e.target.value);
                    }
                    }
                        onKeyUp={async (e) => {
                            if (e.key === 'Enter') {
                                try {
                                    setLoading(true);
                                    const result = await API.post(apiName, '/login', {
                                        body: {
                                            operation: 'auth', data: { username, password }
                                        }
                                    });
                                    setLoading(false);
                                    if (result) {
                                        onLogin({ username: result.username, sessionId: result.sesion_id });
                                        setCookie('usuario', { username, password }, { maxAge: 86000 })
                                    }
                                    else (
                                        setErrorMessage('Datos Incorrectos')
                                    )
                                } catch (error) {
                                    console.log(error);
                                    setLoading(false);
                                    setErrorMessage('Datos Incorrectos');
                                }
                            }
                        }} />
                </div>
            </div>
            {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
            {!loading ? <button type="button" onClick={async () => {
                try {
                    setLoading(true);
                    const result = await API.post(apiName, '/login', {
                        body: {
                            operation: 'auth', data: { username, password }
                        }
                    });
                    setLoading(false);
                    if (result) {
                        onLogin({ username: result.username, sessionId: result.sesion_id });
                        setCookie('usuario', { username, password }, { maxAge: 86000 })
                    }
                    else (
                        setErrorMessage('Datos Incorrectos')
                    )
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                    setErrorMessage('Datos Incorrectos');
                }

            }}>Iniciar Sesión</button> : <ClipLoader />}
        </div>
    </div >)
}

export default Main;