import React from 'react';
import './App.css';
import Login from './routes/public/login';
import Dashboard from './routes/private/dashboard';
import Clientes from './routes/private/clientes';
import { Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { useAuth, AuthContext } from './state/auth/contex';
import { useClienteContext, ClienteContext } from './state/clientes/context';
import Modal from 'react-modal';
import { ICliente } from './state/clientes/types';

Modal.setAppElement('#root')

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Routes>
          <Route index element={<Redirect />} />
          <Route path="login" element={<Login />} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/clientes" element={<ProtectedRoute><Clientes /></ProtectedRoute>} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div >

    </AuthProvider>
  );
}

const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
  const { user } = useAuth();
  const [clienteSeleccionado, setClienteSeleccionado] = React.useState<ICliente | undefined>(undefined);
  const onSetCliente = (clienteSeleccionado: ICliente) => {
    setClienteSeleccionado(clienteSeleccionado);
  }
  if (!user) {
    return <Navigate to="/login" replace />
  }
  const value = {
    clienteSeleccionado,
    onSetCliente
  }
  return (<ClienteContext.Provider value={value}>{children}</ClienteContext.Provider>);
}

const Redirect = () => {
  return <Navigate to="/clientes" replace />
}

const NoMatch = () => <h2>404. Not Found</h2>

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<IUser | undefined>(undefined);

  const handleLogin = (user: IUser) => {
    setUser(user);
    navigate('/clientes')
  }
  const handleLogout = () => {
    setUser(undefined)
  }
  const value = {
    user,
    onLogin: handleLogin,
    onLogout: handleLogout
  }
  return (<AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>);
}
export default App;

interface IUser {
  username: string;
  sessionId?: string;
}