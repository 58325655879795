import { useState, useEffect } from 'react';
import Modal from "react-modal";

const MyButtonComponent = ({ item, children, onClick }: { item: { vehiculo_id: number, command: string }, children: string, onClick: () => void }) => {
    const [disabled, setDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        // Aquí estamos obteniendo el tiempo restante desde el localStorage
        const blockTime = localStorage.getItem(`blockTime-${item.vehiculo_id}-${item.command}`);
        if (blockTime && Date.now() < Number(blockTime)) {
            setDisabled(true);
            const timerId = setTimeout(() => {
                setDisabled(false);
                localStorage.removeItem(`blockTime-${item.vehiculo_id}-${item.command}`);
            }, Number(blockTime) - Date.now());
            return () => clearTimeout(timerId);
        }
    }, [item]);

    const handleClick = () => {
        // Cuando se hace clic en el botón, se bloquea durante 5 minutos.
        onClick();
        setDisabled(true);
        const blockTime = Date.now() + 30 * 1000;
        localStorage.setItem(`blockTime-${item.vehiculo_id}-${item.command}`, blockTime.toString());
        setTimeout(() => {
            setDisabled(false);
            localStorage.removeItem(`blockTime-${item.vehiculo_id}-${item.command}`);
        }, 5 * 60 * 1000);
    };

    return (
        <>
            <button disabled={disabled} onClick={() => setShowModal(true)}>
                {children}
            </button>
            <Modal
                isOpen={showModal}
                style={{
                    content: {
                        zIndex: 3,
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                    },
                }}
            >
                <h1 style={{ textTransform: 'capitalize' }}>
                    {item.command}
                </h1>
                <div style={{
                    marginBottom: 10
                }}>¿Estás seguro de realizar esta acción?</div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <button onClick={() => { handleClick(); setShowModal(false) }}>Aceptar</button>
                    <button onClick={() => setShowModal(false)}>Cancelar</button>
                </div>

            </Modal>
        </>
    );
};

export default MyButtonComponent;